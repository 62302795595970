<template>
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item carousel-image bg-img-1 active">
      <!-- <img src="https://i.imgur.com/ZtazxTO.jpg" class="d-block w-100" alt="Condominio"> -->
      <div class="carousel-caption d-none d-md-block">
        <img src="../assets/dafal-removebg.png" alt="" class="logo">
      </div>
    </div>
    <div class="carousel-item carousel-image bg-img-2">
      <!-- <img src="https://i.imgur.com/9hfWT2B.jpg" class="d-block w-100" alt="Parque"> -->
      <div class="carousel-caption d-none d-md-block">
        <h1>Contable, administrativos, legales y financieros</h1>        
      </div>
    </div>
    <div class="carousel-item carousel-image bg-img-3">
      <!-- <img src="https://i.imgur.com/MRsVFRD.jpg" class="d-block w-100" alt="Departamento"> -->
      <div class="carousel-caption d-none d-md-block">
        <h1>ComunidadFeliz, Edifito, Excel y diversas plataformas</h1>        
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</template>

<style scoped>

.logo {
  max-width: 700px;
  width: 100%; /* Asegura que la imagen ocupe el 100% del contenedor */
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-img-1 {
    background-image: url(https://i.imgur.com/ZtazxTO.jpg);
}

.bg-img-2 {
    background-image: url(https://i.imgur.com/9hfWT2B.jpg);
}

.bg-img-3 {
    background-image: url(https://i.imgur.com/MRsVFRD.jpg);
}

.carousel-image {
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.carousel-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    padding: 20px; 
    background-color: rgba(26, 24, 24, 0.849);
    height: 300px;
    text-align: center;
}

.carousel-caption h1 {
    font-size: 4em;
    color: #ffff; 
    font-weight: bold;
    margin-top: 100px;    
}
</style>