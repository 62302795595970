<template>
<nav class="navbar navbar-expand-lg bg-body-tertiary" id="barra">
  <div class="container-fluid">
    <img src="./assets/dafal-removebg.png" alt="" class="logo">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
      <a @click="scrollToSection('#home')" class="nav-link">Inicio</a>
      <a @click="scrollToSection('#about')" class="nav-link">Sobre Nosotros</a>
      <a @click="scrollToSection('#servicios')" class="nav-link">Servicios</a>
      <a @click="scrollToSection('#contacto')" class="nav-link">Contactanos</a>
    </div>
    <div class="btns ms-auto">
      <button type="button" class="btn btn-outline-light bi bi-facebook me-2"></button>
      <button type="button" class="btn btn-outline-light bi bi-instagram me-2" onclick="window.open('https://www.instagram.com/dafalcl/', '_blank')"></button>
      <button type="button" class="btn btn-outline-light bi bi-whatsapp" onclick="window.open('https://wa.me/56991731425', '_blank')"></button>  
      </div>
    </div>
  </div>
</nav>  

<div id="home" class="section">     
  <HomeView />
</div>

<div id="about" class="section">      
  <AboutView />
</div>

<div id="servicios" class="section" ref="servicios"> 
  <ServiciosView />
</div>

<div id="contacto" class="section">    
  <ContactView />
</div>

<!-- Ícono de WhatsApp -->
<div class="whatsapp-icon">
  <a href="https://wa.me/56991731425" target="_blank" rel="noopener noreferrer">
    <img src="./assets/Whatsapp.png" alt="WhatsApp" style="width: 90px; height: auto;">
  </a>
</div>

<footer class="mt-5 p-3 text-white">
  <div class="container">
    <div class="row">          
      <div id="pie" class="col-md-4">
        <p>
          En Dafal, creemos en construir relaciones sólidas y duraderas, basadas en la transparencia, la confianza y la excelencia en el servicio.
        </p>
      </div>
          
      <div class="col-md-4 text-center">            
        <img src="./assets/dafal-removebg.png" alt="Logo de Dafal" style="max-width: 100%; border-radius: 50%;">
        <p class="mt-3">&copy; Ronald Campillay Pizarro</p>
      </div>
          
      <div class="col-md-4 mt-5">
        <div>
          <i class="bi bi-geo-alt-fill"></i> Dirección: Reñaca Norte 265 of 510, Viña del Mar
        </div>
        <div>
          <i class="bi bi-phone"></i> Teléfono: +569 91731425
        </div>
        <div>
          <i class="bi bi-instagram"></i> Instagram: @dafalcl
        </div>
        <div>
          <i class="bi bi-envelope"></i> Correo: Contacto@dafal.cl
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import HomeView from './views/HomeView.vue';
import AboutView from './views/AboutView.vue'
import ContactView from './views/ContactView.vue';
import ServiciosView from './views/ServiciosView.vue';


export default {
  name: 'App',
  components: {
    HomeView,   
    AboutView,
    ContactView,
    ServiciosView
  },
  methods: {
    scrollToSection(selector) {
      const targetElement = document.querySelector(selector);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style>
.logo {
  width: 100%;
  max-width: 150px;
  border-radius: 50%; 
}

.navbar-nav .nav-link {
  color: white;
  font-weight: bold;
}
.navbar-nav .nav-link:hover {
  color: #CCCCCC; /* Puedes ajustar el color según tu preferencia */
}
#barra {
  background-color: #050908 !important; /* o background-color: black; */
}

footer {
  background-color: #050908;
  color: white;
}
#pie{
  text-align: justify;
  margin-top: 60px;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px; /* Ajusta la distancia desde el borde inferior */
  right: 20px; /* Ajusta la distancia desde el borde derecho */
  z-index: 1000; /* Ajusta según sea necesario para que esté por encima de otros elementos */

}
</style>