<template>
  <div class="about" id="about-view">
    <h1><strong>Dafal Administración de Copropiedades</strong></h1>
    <div class="content-container">
      <img src="https://i.imgur.com/bairYf3.jpg" alt="" class="image">
      <div class="contenido">         
        <h3 class="primero">
          <strong>Mejoramos Tu Comunidad a través de la Plusvalía</strong>
        </h3>
        <p>
          <span>
            Aportamos plusvalía a tu comunidad realizando las gestiones necesarias con la mayor eficiencia y eficacia de los recursos, mejorando la calidad de vida de los vecinos.
          </span>
        </p>
        <br/>
        <h3>
          <strong>Optimización Total para Tu Condominio</strong>
        </h3>
        <p>
          <span>
            Ejecución de proyectos, aplicación nueva Ley de Copropiedad, gastos comunes al día, gestiones contables y legales para tu condominio y edificio.
            Usamos y tenemos experiencia en distintas plataformas de administración, tales como Comunidad feliz, Edifito, etc. También trabajamos con planillas Excel.
          </span>
        </p>
        <br/>
        <h3>
          <strong>Transparencia en Acción</strong>
        </h3>
        <p>
          <span>
            La transparencia en nuestra gestión, es nuestro enfoque principal, para eso estamos constantemente atendiendo las inquietudes de la comunidad a través de las vías más efectivas para llevar dicha transparencia a efecto.
          </span>          
        </p>
        <br/>
        <h3>
          <strong>Excelencia Legal y Contable</strong>
        </h3>
        <p>
          <span>
            Contamos con área legal y contable, con experiencia garantizada con nota máxima por la Pontificia Universidad Católica de Chile en cuanto a la normativa legal vigente de la ley 21.442.
          </span>
        </p>
        <br/>
        <h3>
          <strong>Garantizando Conformidad Legal</strong>
        </h3>
        <p>
          <span>
            Aplicamos el reglamento de la comunidad, hacemos interpretacion conforme a la ley y apoyamos a las comunidades para que sus reglamento se encuentre acorde a la normativa legal vigente.
          </span>
        </p>
        <br/>
        <h3>
          <strong>Apoyo Back Office para Administradores</strong>
        </h3>
        <p>
          <span>
            ¿Quieres externalizar funciones? Hazlo, y nosotros te ayudaremos. Ofrecemos planes básicos que van desde tan solo $690 pesos por unidad hasta planes más avanzados y complejos. También proporcionamos gestión de RRHH por tan solo $8.000 pesos mensuales por trabajador. Consulta nuestros planes, servicios y condiciones.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about p {
  text-align: justify;
  font-size: large;  
}


.about h1 {
  padding-top: 75px;
}

.about h1, p, h3 {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Modificación aquí */
}

.image {
  width: 50%;
  margin: 25px; /* Márgenes en todos los lados para la imagen */
  border-radius: 50px;
}

.contenido {
  width: 50%;
  margin: 25px; /* Márgenes en todos los lados para el texto */
}

/* Ajustado para mantener el espacio entre los títulos y los párrafos */
.contenido h3,
.contenido p {
  margin-bottom: 21px;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    margin: 0 auto; /* Centra el contenido en la pantalla */
    padding: 20px;      /* Cambia a diseño de columna en pantallas más pequeñas */
  }

  .image, .contenido {
    width: 100%; /* Ocupa el ancho completo en pantallas más pequeñas */
    margin: 0; /* Elimina márgenes para aprovechar el espacio */
  }

  .contenido {
    margin-top: 20px;
  }
  .primero {
    margin-top: 50px;
  }
}

@media (min-width: 1300px) {
  .image {
    max-height: 100vh; /* Ajusta este valor según tus necesidades */
  }
}

</style>